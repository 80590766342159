@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

code {
    font-family: 'Source Code Pro', monospace;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background-color: black;

    &.inline {
        color: black;
        background-color: white;
    }

    &.block {
        margin: 8px;
        padding: 8px;
        display: block;
        width: fit-content;
    }
}
