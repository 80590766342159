@import './wbce-colors.scss';

.encoche,
.theming-encoche {
    display: flex;
    align-items: center;
    &::before {
        content: '';
        border-left: 2px solid;
        height: 15px;
        margin-left: -8px;
        margin-right: 8px;
    }
}

.theming-encoche {
    &::before {
        border-color: var(--theme-color);
    }
}
