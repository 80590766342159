$wbce-red: #ff576d;
$wbce-light-blue: #82cbff;
$wbce-dark-blue: #252d89;
$wbce-green: #38d4cc;
$wbce-orange: #ff9960;
$wbce-purple: #7c8ae8;
$wbce-dark-white: #f8f9fa;
$wbce-light-grey: #e9e9e9;
$wbce-medium-grey: #bcbcbc;
$wbce-dark-grey: #636363;
$wbce-grey-background: #e5e5e5;
$wbce-yellow: #fff176;
$wbce-medium-green: #38d4a5;
