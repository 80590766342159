@import './wbce-colors.scss';

a.wbce {
    cursor: pointer;

    &:hover {
        color: $wbce-light-blue;
    }

    mat-icon {
        text-decoration: none;
    }

    span {
        text-decoration: underline;
    }
}
