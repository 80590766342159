@import './wbce-colors.scss';

fieldset.wbce {
    width: 180px;
    height: 56px; //42px + text legend of 14px
    min-height: 56px;
    //padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    border: 1px solid black;
    //color:black;
    padding-top: 8px;
    padding-bottom: 8px;

    legend {
        @extend .mat-body-2;
        margin-left: 16px;
    }

    > * {
        padding-left: 16px;
        padding-right: 16px;
        align-self: center;
    }

    span input {
        // waiting for others
        width: 100%;
    }

    mat-select {
        @extend .mat-body-2;

        .mat-mdc-select-arrow {
            color: black;
        }
    }

    input {
        border: 0;
        outline: 0;
        margin-top: auto;
        margin-bottom: auto;
        :focus {
            outline: none !important;
        }
    }

    &:hover {
        border-color: grey;
    }
}

fieldset.wbce:has(> textarea) {
    height: 160px;
    textarea {
        height: 100%;
        border: none;

        &:focus {
            outline: none;
        }
    }
}
