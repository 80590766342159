@import './wbce-colors.scss';

.cdk-global-overlay-wrapper {
    background-color: rgba($wbce-dark-blue, 0.5);
}

mat-mdc-dialog-container {
    display: flex !important;
    flex-direction: column !important;
}

.wbce-dialog {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    padding: 32px;

    &.full-width {
        width: 100%;
    }

    &.start {
        justify-content: flex-start;
        padding: 16px;
        gap: 24px;
    }

    &.gap16 {
        gap: 16px;
    }

    &.no-overflow {
        overflow: hidden;
    }
}

#wbce-dialog-close-icon {
    position: absolute;
    top: 0;
    right: 0;
    // float: right;
    cursor: pointer;
}

.wbce-dialog-title {
    display: flex !important;
    justify-content: center !important;
    mat-icon {
        margin-right: 16px !important;
    }

    &.margin-top-t32 {
        margin-top: 32px;
    }
}

.wbce-dialog-content {
    display: flex !important;

    &.column {
        flex-direction: column;
    }

    &.start {
        justify-content: flex-start;
    }

    &.full-height {
        padding: 0px;
        height: 100%;
        max-height: 100%;
    }

    &.row {
        flex-direction: row;
    }

    &.center {
        justify-content: center;
    }

    &.item-center {
        align-items: center;
    }

    &.padding-max {
        padding-left: 56px;
        padding-right: 56px;

        &.content-div {
            div {
                width: 100%;
            }
        }

        &.overflow {
            overflow-y: auto;
        }
    }

    &.gap16 {
        gap: 16px;
    }

    &.with-content {
        gap: 32px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;

        fieldset {
            width: calc(100% - 18px);
            display: inline-block;
            border-color: $wbce-medium-grey;

            &.logs {
                flex-grow: 2;
                display: block;
                min-height: unset;
                height: unset;
            }
        }
    }

    &.grey-fieldset {
        fieldset {
            border-color: $wbce-medium-grey;
        }
    }
}

.wbce-dialog-actions {
    margin-top: auto;
    display: flex;
    justify-content: center;
    app-wbce-button {
        padding-right: 8px;
    }

    &.comment {
        flex-direction: column;
        gap: 16px;
    }
}

.wbce-dialog-line {
    display: flex;
    width: 100%;

    &.column {
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 16px;
        gap: 16px;
        box-sizing: border-box;
    }

    &.row {
        flex-direction: row;
        gap: 8px;
    }

    fieldset {
        // later move fieldset globally
        border-color: #bcbcbc;
        width: 100%;
        min-height: 48px !important;
        height: 48px !important;
        margin: 8px;

        input {
            width: 100%;
        }
    }
}

.wbce-dialog-warning-message {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 16px;

    mat-icon {
        width: 30px !important;
    }
}

.wbce-dialog-cartel {
    overflow: unset;
}
