.wbce-placeholder-content {
    background: #000;

    // Animation
    animation-duration: 1.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeholderAnimate;
    background: #f6f7f8; // Fallback
    background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
    background-size: 1000px; // Animation Area
}

@keyframes placeholderAnimate {
    0% {
        background-position: -500px 0;
    }
    100% {
        background-position: 500px 0;
    }
}
