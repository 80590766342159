@use '@angular/material' as mat;

// import font awesome
$fa-fort-path: '@fontawesome/fontawesome-free/webfonts';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/brands';

// Override the typography in the core CSS.
@include mat.core();

// import our custom theme
@import './styles/wbce-theme.scss';
@import './styles/wbce-colors.scss';

// use our theme with angular-material-theme mixin
@include mat.typography-hierarchy($wbce-typography);
@include mat.all-component-themes($wbce-theme);

/* You can add global styles to this file, and also import other style files */
@import './styles/wbce-select.scss';
@import './styles/wbce-cartel.scss';
@import './styles/wbce-encoche.scss';
@import './styles/wbce-snackbar.scss';
@import './styles/wbce-code.scss';
@import './styles/wbce-tabs.scss';
@import './styles/wbce-card.scss';
@import './styles/wbce-button.scss';
@import './styles/wbce-dialog.scss';
@import './styles/wbce-table.scss';
@import './styles/wbce-status-badge.scss';
@import './styles/wbce-tags.scss';
@import './styles/wbce-link.scss';
@import './styles/wbce-container.scss';
@import './styles/wbce-placeholders.scss';
