@import './wbce-colors.scss';

.wbce-tags-primary {
    background: rgba($wbce-medium-green, 0.05);
    border: 1px solid darken($wbce-medium-green, 10%);
    color: darken($wbce-medium-green, 10%);

    &.hovered {
        &:hover {
            background: rgba($wbce-medium-green, 0.3);
        }
    }
}

.wbce-tags-error {
    background: rgba($wbce-red, 0.05);
    border: 1px solid darken($wbce-red, 10%);
    color: darken($wbce-red, 10%);

    &.hovered {
        &:hover {
            background: rgba($wbce-red, 0.3);
        }
    }
}

.wbce-tags-warning {
    background: rgba($wbce-orange, 0.05);
    border: 1px solid darken($wbce-orange, 10%);
    color: darken($wbce-orange, 10%);

    &.hovered {
        &:hover {
            background: rgba($wbce-orange, 0.3);
        }
    }
}

.wbce-tags-waiting {
    background: rgba($wbce-light-grey, 0.05);
    border: 1px solid darken($wbce-light-grey, 10%);
    color: darken($wbce-medium-grey, 10%);

    &.hovered {
        &:hover {
            background: rgba($wbce-light-grey, 0.3);
        }
    }
}

.wbce-tags-accent {
    background: rgba($wbce-light-blue, 0.05);
    border: 1px solid darken($wbce-light-blue, 10%);
    color: darken($wbce-light-blue, 10%);

    &.hovered {
        &:hover {
            background: rgba($wbce-light-blue, 0.3);
        }
    }
}

.wbce-tags-inactive {
    background-image: linear-gradient(
        45deg,
        rgba($wbce-light-grey, 0.7) 25%,
        transparent 25%,
        transparent 50%,
        rgba($wbce-light-grey, 0.7) 50%,
        rgba($wbce-light-grey, 0.7) 75%,
        transparent 75%,
        transparent
    );
    border: 1px solid darken($wbce-light-grey, 10%);
    color: darken($wbce-light-grey, 10%);

    &.hovered {
        &:hover {
            background-image: linear-gradient(
                45deg,
                rgba($wbce-light-grey, 1) 25%,
                transparent 25%,
                transparent 50%,
                rgba($wbce-light-grey, 1) 50%,
                rgba($wbce-light-grey, 1) 75%,
                transparent 75%,
                transparent
            );
        }
    }
}

div.wbce-tags-primary,
div.wbce-tags-error,
div.wbce-tags-warning,
div.wbce-tags-accent,
div.wbce-tags-inactive,
div.wbce-tags-waiting {
    border-radius: 16px;
    height: 30px;
    // width: 80px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;

    &.detail {
        width: 200px !important;
        -webkit-transition: width 0.5s ease-in-out;
        -moz-transition: width 0.5s ease-in-out;
        -o-transition: width 0.5s ease-in-out;
        transition: width 0.5s ease-in-out;
    }

    .wbce-tags-label {
        text-transform: uppercase;
        @extend .mat-caption;
        margin: auto;

        &.detail {
            text-transform: none;
        }
    }

    &.hovered {
        &:hover {
            cursor: pointer;
        }
    }
}
