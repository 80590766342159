@use '@angular/material' as mat;

@import './wbce-colors.scss';

@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

$mat-black-perso: (
    50: #e0e0e0,
    100: #b3b3b3,
    200: #808080,
    300: #4d4d4d,
    400: #262626,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #a6a6a6,
    A200: #8c8c8c,
    A400: #737373,
    A700: #666666,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$mat-blue-perso: (
    50: #f0f9ff,
    100: #daefff,
    200: #c1e5ff,
    300: #a8dbff,
    400: #95d3ff,
    500: #82cbff,
    600: #7ac6ff,
    700: #6fbeff,
    800: #65b8ff,
    900: #52acff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #f7fbff,
    A700: #deefff,
    contrast: (
        50: #65b8ff,
        100: #65b8ff,
        200: #65b8ff,
        300: #65b8ff,
        400: #daefff,
        500: #daefff,
        600: #daefff,
        700: #daefff,
        800: #daefff,
        900: #daefff,
        A100: #65b8ff,
        A200: #65b8ff,
        A400: #65b8ff,
        A700: #65b8ff,
    ),
);

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
// args supp
// $button: null,
// $overline: null,

$wbce-typography: mat.m2-define-typography-config(
    $font-family: 'Rubik',

    $headline-1: mat.m2-define-typography-level(56px, 62px, 700),
    $headline-2: mat.m2-define-typography-level(44px, 50px, 700),
    $headline-3: mat.m2-define-typography-level(32px, 40px, 600),
    $headline-4: mat.m2-define-typography-level(28px, 36px, 600),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 600),
    $headline-6: mat.m2-define-typography-level(22px, 28px, 600),
    $subtitle-1: mat.m2-define-typography-level(18px, 24px, 400),
    $subtitle-2: mat.m2-define-typography-level(16px, 20px, 600),
    $body-1: mat.m2-define-typography-level(14px, 20px, 400),
    $body-2: mat.m2-define-typography-level(12px, 16px, 400),
    $caption: mat.m2-define-typography-level(10px, 14px, 400),
);

// Define your application's custom theme.
$primary: mat.m2-define-palette($mat-black-perso, 900);
$accent: mat.m2-define-palette($mat-blue-perso, 500); // , A100, 100, A400, 50
$warn: mat.m2-define-palette(
    mat.$m2-red-palette
); // The warn palette is optional (defaults to red).

$wbce-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $primary,
            accent: $accent,
            warn: $warn,
        ),
        typography: $wbce-typography,
    )
);

/* You can add global styles to this file, and also import other style files */
:root {
    // couleur par defaut et alignement gauche par defaut
    --theme-color: black;
    --alignementLeft: col 1;
}

html,
body {
    height: 100%;
    box-sizing: border-box;
}
body {
    margin: 0;
    background: $wbce-dark-white;
    overflow: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

h2 {
    //ecrase la config mat-title
    margin-bottom: 8px !important;
}

app-root {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: [start] 24px repeat(
            12,
            [col] minmax(5%, 1fr)
        ) [col-end] 24px [end];
    //usage de min-max : https://css-tricks.com/preventing-a-grid-blowout/
    grid-template-rows:
        [start] 8px
        [content-start] 32px
        [content-end] 8px
        repeat(auto-fill, [row] 8px)
        [row-end] 24px [end];
    grid-auto-flow: column;
    column-gap: 24px;

    --mdc-filled-text-field-container-color: white;
}

.mat-mdc-form-field-focus-overlay {
    background-color: white !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;
}
