@import './wbce-colors.scss';

app-wbce-button {
    &.full-width button {
        width: 100%;
    }

    &.warning button {
        border-color: $wbce-red !important;
        color: $wbce-red !important;

        &:disabled {
            border-color: rgba($wbce-red, 30%) !important;
            color: rgba($wbce-red, 30%) !important;
        }
    }
}
