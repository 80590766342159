// various css content

.wbce-container {
    display: flex;

    &.centered {
        justify-content: center;
        align-items: center;
    }

    &.c-sb {
        flex-direction: column;
        justify-content: space-between;
    }

    &.gap32 {
        gap: 32px;
    }
}

.logo-v1 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    width: 100%;
    object-fit: cover;
}

app-wbce-button {
    display: flex;
    justify-content: center;
    align-items: center;

    &.resize63x34 {
        width: 63px !important;
        height: 34px !important;
    }

    div {
        display: flex;
        gap: 3px;
    }

    &.menu-list {
        width: 100%;

        div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 24px;
            position: relative;
            width: 100%;
            padding-left: 8px;
        }
    }
}

.wbce-code {
    padding: 8px;
    width: 100%;
    max-width: 100%;
    overflow-wrap: break-word;
    word-break: break-all;
    overflow: auto;
    display: block;
    font-size: 12px;

    &.fit-content {
        min-height: 100%;
        height: fit-content;
    }

    &.full-height {
        height: 100%;
    }
}

.wbce-pre {
    width: 100%;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap; /* Mozilla */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE5.5+ */
}

.mat-input-invalid .mat-input-placeholder {
    color: $wbce-red;
}

.mat-input-invalid .mat-input-ripple {
    background-color: $wbce-red;
}

mat-error {
    color: $wbce-red;
}
