@import './wbce-colors.scss';

@keyframes loader {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(0.7, 0.7);
    }
    100% {
        transform: scale(1, 1);
    }
}

@mixin status-badge($color) {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    flex-shrink: 0; // prevent shrinking
    background: $color;
    box-shadow: 0px 0px 8px $color;
}

.status-badge {
    &-animated {
        animation: loader 2s infinite ease;
    }

    &-ok {
        @include status-badge(#38d4a5);
    }

    &-warning {
        @include status-badge($wbce-orange);
    }

    &-error {
        @include status-badge($wbce-red);
    }

    &-active {
        @include status-badge($wbce-light-blue);
    }

    &-inactive {
        @include status-badge($wbce-light-grey);
        background: none;
        background-image: linear-gradient(
            45deg,
            $wbce-light-grey 25%,
            transparent 25%,
            transparent 50%,
            $wbce-light-grey 50%,
            $wbce-light-grey 75%,
            transparent 75%,
            transparent
        );
    }

    &-waiting {
        @include status-badge($wbce-light-grey);
    }
}
