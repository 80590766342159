@import './wbce-colors.scss';

.wbce-cartel {
    border-radius: 8px;
    padding: 24px;
    background-color: white;
    overflow: auto;
}

.wbce-note-cartel {
    border-radius: 8px;
    padding: 8px;
    overflow: auto;
    background: rgba($wbce-light-blue, 0.05);
    border: 1px solid darken($wbce-light-blue, 10%);
    color: darken($wbce-light-blue, 10%);
}

// a supprimer et a remplacer par card
